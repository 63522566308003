import { createWebHistory, createRouter } from 'vue-router';
import Home from '../views/Home.vue';
import StarterPage from '../views/StarterPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/starter-page',
    name: 'Starter Page',
    component: StarterPage,
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

export default router;
