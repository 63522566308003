<template>
  <div class="flex flex-col flex-grow w-full">
    <Navbar />
    <div class="flex flex-col items-center flex-grow">
      <div class="font-sans">
        <div>My name is Lachlan. I make stuff</div>
        <div class="mt-4 ml-5">
          <ul class="list-disc">
            <li>Rust</li>
            <li>PyTorch</li>
            <li>Computer Vision</li>
            <li>Machine Learning</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
  },
};
</script>
