<template>
  <div class="container">
    <p class="title">Hello!</p>

    <div class="category-container">
      <div class="category-card">
        <p class="card-title">Feeds</p>
        <div class="card-title-break"></div>
        <a class="card-link" href="https://news.ycombinator.com/">Hacker News</a>
        <a class="card-link" href="https://www.youtube.com/">YouTube</a>
        <a class="card-link" href="https://www.reddit.com/r/formula1/">/r/Formula1</a>
        <a class="card-link" href="https://lobste.rs/">Lobste.rs</a>
      </div>
      <div class="category-card">
        <p class="card-title">Life</p>
        <div class="card-title-break"></div>
        <a class="card-link" href="https://mail.google.com/mail/u/0/#inbox">Gmail</a>
        <a class="card-link" href="http://www.bom.gov.au/vic/forecasts/melbourne.shtml">Melbourne Weather</a>
        <a class="card-link" href="http://www.bom.gov.au/products/IDR023.loop.shtml">Melbourne Radar</a>
        <a class="card-link" href="http://tramtracker.com.au/">Tram to Work (1842)</a>
        <a class="card-link" href="http://tramtracker.com.au/">Tram from Work (2815)</a>
      </div>
      <div class="category-card">
        <p class="card-title">Dev</p>
        <div class="card-title-break"></div>
        <a class="card-link" href="https://gitlab.com/">Gitlab</a>
        <a class="card-link" href="https://docs.rs/glam/latest/glam/">glam</a>
        <a class="card-link" href="https://ntex.rs/">ntex</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
html, body {
  margin: 0;
  background-color: #232136;
  color: #eae8ff;
  font-family: monospace, monospace;
  font-size: 16px;
}

p {
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 32px;
  margin: 8px 0;
}

.category-container {
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 8px;
  justify-content: space-around;
}

.category-card {
  display: flex;
  flex-direction: column;
  background-color: #373354;
  border-radius: 3px;
  min-width: 256px;
  margin: 0 16px;
  padding: 4px 8px;
}

.card-title-break {
  border-bottom: 1px solid #63577d;
  margin-bottom: 4px;
}

.card-link {
  color: #eae8ff;
  text-decoration: underline;
  margin: 4px 8px;
}

.card-title {
  text-transform: uppercase;
  font-size: 20px;
  padding: 4px 0;
  text-align: center;
}
</style>
