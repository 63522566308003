<template>
  <div
    id="app"
    class="flex flex-col items-center w-full h-screen text-white"
  >
    <RouterView />
  </div>
</template>

<script setup></script>

<style>
#app {
  background-color: #232136;
}
</style>
