<template>
  <div class="flex p-4">
    <div class="flex items-start w-1/2 mx-auto">
      <div class="rounded-md px-2 pb-2 pt-1 font-bold text-xl lz-icon">
        LZ
      </div>
    </div>
  </div>
</template>

<style scoped>
.lz-icon {
  background-color: #373354;
}
</style>
